<template>
  <router-link :to="derivedTo">
    <slot />
  </router-link>
</template>
<script>
import { HASH, ROUTE_COMPONENT } from '@/constants'
import { isString, isNil } from '@/helpers'

export default {
  name: ROUTE_COMPONENT,
  props: {
    to: {
      default: HASH,
      type: [String, Object],
    },
  },
  computed: {
    derivedTo() {
      if (isString(this.to)) {
        return this.to
      }

      const derivedTo = { ...this.to }
      isNil(derivedTo.params) && (derivedTo.params = {})
      derivedTo.params.lang = this.$i18n.locale

      return derivedTo
    },
  },
}
</script>
